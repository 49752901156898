<template>
  <div>
    <!-- <img src="https://res.cloudinary.com/mhmd/image/upload/v1569543678/form_d9sh6m.svg" alt="" class="img-fluid mb-3 d-none d-md-block" /> -->
    <img :src="imgsrc" alt="" class="img-fluid mb-3 d-none d-md-block pg-img" />
    <h1 style="color:white">{{ heading }}</h1>
    <p class="font-italic mb-0" style="color:white">{{ description }}</p>
  </div>
</template>

<script>
export default {
  name: "PageHeading",
  props: {
    heading: String,
    description: String,
    imgsrc: String,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.pg-img {
  margin-left: auto;
  margin-right: auto;
}
</style>