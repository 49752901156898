import axios from "axios"
import apiConfig from '../config/api.config';

export async function getCreditExchangeRate() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_CREDIT_EXCHANGE_RATE)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}
export async function getResellerCreditPurchaseTransactions(resellerId, page, requestorEmail, dateRangeObj = {}) {
    const params = {
        resellerId: resellerId,
        requestorEmail: requestorEmail,
        page: page,
        access_token: localStorage.getItem("access_token"),
        dateRange: dateRangeObj
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_PAGED_RESELLER_RAZORPAY_PAYMENT, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export async function checkPaymentStatus(txnId,txnType="grant"){
    const params = {
        mfTxnId:txnId,
        txnType : txnType
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.CHECK_PAYMENT_ORDER,{params})
        .then(response => {
            if (response.status === 200 && response.data.success)
                resolve(response.data.result);
            else
                reject(response.data.errMsg);
        })
        .catch(error => {
            console.error(error);
            rejectWithErrorMsg(reject, error);
        });
    })
}

// Razorpay

export async function getRazorpayKey() {
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_RAZORPAY_KEY)
            .then(response => {
                resolve(response.data);
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}
export async function createGiftCardCheckout(payload){
    
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GIFTCARD_REDEEM, payload)
            .then(response => {
                if (response.status === 200 && response.data.success)
                    resolve(response.data.result);
                else
                    reject(response.data.errMsg);
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export async function createCheckout(payload) {
    
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_PAYMENT_ORDER, payload)
            .then(response => {
                if (response.status === 201 && response.data.success)
                    resolve(response.data.result);
                else
                    reject(response.data.errMsg);
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export async function verifyOTP(payload) {
    
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.VERIFY_ZAIN_OTP, payload)
            .then(response => {
                if (response.status === 200 && response.data.success)
                    resolve(response.data.result);
                else
                    reject(response.data.errMsg);
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

// Bkash

export async function checkBkashPaymentStatus(spTransID, requestorEmail) {
    let params = {
        requestorEmail: requestorEmail,
        spTransID: spTransID,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.CHECK_BKASH_PAYMENT_STATUS, { params })
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function getBKashPaymentStatus(requestorEmail, spTransID) {
    let payload = {
        requestorEmail: requestorEmail,
        spTransID: spTransID,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.BKASH_PAYMENT_VERIFICATION, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error getting response from payment server. Please reach out to our support if money is deducted and credits not updated");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function sendBkashResolve(spTransID, amount, mfCredits, status, resellerEmailId, requestorEmail) {
    let payload = {
        spTransID: spTransID,
        amount: amount,
        mfCredits: mfCredits,
        resellerEmailId: resellerEmailId,
        status: status,
        requestorEmail: requestorEmail,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.REFUND_BKASH_PAYMENT, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(true);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function createBKashOrder(requestorEmail, amount) {
    let payload = {
        requestorEmail: requestorEmail,
        amount: amount,
        access_token: localStorage.getItem("access_token")
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_BKASH_ORDER, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error initiating the payment. Please try again!");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

function rejectWithErrorMsg(reject, error) {
    if (error && error.response && error.response.data && error.response.data.errMsg) reject(error.response.data.errMsg)
    else reject("Error in connection");
}

export async function createAamarpayOrder(requestorEmail, amount, mfValue) {
    let payload = {
        requestorEmail: requestorEmail,
        amount: amount,
        mfValue: mfValue,
        access_token: localStorage.getItem("access_token"),
        url: window.location.href
    }
    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.CREATE_AAMARPAY_ORDER, payload)
            .then(response => {
                if (!response || !response.data) return reject("Error initiating the payment. Please try again!");
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
                console.error(err);
                rejectWithErrorMsg(reject, err);
            });
    });
}

export async function checkGiftCardBalance(cardNumber,voucherSource,voucherPin=null) {
    let params;
    if(voucherPin){
    params = {
        voucherSource: voucherSource,
        voucherCode: cardNumber,
        pin: voucherPin
    };
    }
    else{
        params = {
            voucherSource: voucherSource,
            voucherCode: cardNumber
        };
    }
    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GIFTCARD_VALIDATE, {params})
            .then(response => {
                if (!response) return reject("Error in validating the gift card. Please try again !");
                if(response.data.result){
                    resolve(response.data.result);
                }
                else {
                    reject(response.data.errMsg);
                }
            }).catch(err => {
            console.error(err);
            rejectWithErrorMsg(reject, err);
        });
    });
}
export const getGamePackages = async (gameId, targetPid) => {
    const payload = { gameId: gameId, targetPid: targetPid};

    return new Promise((resolve, reject) => {
        axios.post(process.env.VUE_APP_URL + apiConfig.routes.GET_STORE_PACKAGES, payload)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}
export const getGames = async (store="default") => {

    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_STORE_GAMES + `?store=${store}`)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}

export const getStoreConfig = async () => {

    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_STORE_CONFIG + "?store=default")
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}
export const getAvailablePaymentOption = async () => {

    return new Promise((resolve, reject) => {
        axios.get(process.env.VUE_APP_URL + apiConfig.routes.GET_AVAILABLE_PAYMENTS)
            .then(response => {
                if (response.data.success) {
                    resolve(response.data.result);
                } else {
                    reject(response.data.errMsg);
                }
            })
            .catch(error => {
                console.error(error);
                rejectWithErrorMsg(reject, error);
            });
    });
}