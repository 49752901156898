export const countryCodes = ["IN", "BD"];

export const taxRates = {
    "IN": 18,
    "BD": 5
}
export const TransactionType = {
    MFCredit : "credit",
    PackageCredit : "grant"
}
export const minCreditCountry = {
    "IN": 50,
    "BD": 500
}

export const maxCreditCountry = {
    "IN": 400000,
    "BD": 100000
}

export const countryCode = {
    "IN": "IN",
    "BD": "BD"
}

export const currencyCountry = {
    "IN": "INR",
    "BD": "BDT"
}

export const razorpayConstants = {
    "AUTHORIZED": "authorized",
    "CAPTURED": "captured"
}

export const paymentConstants = {
    "SUCCESS": "SUCCESS",
    "PENDING": "PENDING",
    "APPROVED": "APPROVED",
    "FAILED": "FAILED"
}

export const resellerConstants = {
    "ACTIVE": "ACTIVE",
    "PENDING": "PENDING",
    "DEACTIVATED": "DEACTIVATED",
    "REJECTED": "REJECTED",
}

export const userTypeConstants = {
    "RESELLER": "RESELLER",
    "MFOPS": "MFOPS",
    "SUBRESELLER": "SUBRESELLER",
    "RETAILER": "RETAILER"
}

export const MAX_DATE_RANGE = 90

export const filterPackageConfig = {
    "CHIPS_INVENTORY": "Chips",
    "MF_CREDITS_PRICE": 100,
    "TPG_GAME_ID": 123
}

export const signzyConstants = {
    "REQUESTED": "REQUESTED",
    "REQUEST_KYC": "Request KYC",
    "PENDING_KYC": "KYC Pending",
    "VIEW_KYC": "View KYC Contract"
}

export const colors = {
    "ACTIVE": "green",
    "PENDING": "orange"
}
export const MINTROUTE = "mintroute"
export const QWIKCILVER = "qwikcilver"
export const STELLR = "stellr"
export const EZPIN = "ezpin"
export const NONE = "none"
export const GiftVoucherPartners = [
    {"id":MINTROUTE, "name" : "Mint Route"},
    {"id":QWIKCILVER,"name" : "Qwik Cilver"},
    {"id":STELLR,"name" : "Stellr"},
    {"id" : EZPIN,"name" : "Ezpin"}
]
export const TransactionStatus =  {
    SUCCESS : "SUCCESS",
    PENDING : "PENDING",
    FAILED : "FAILED",
    INVALID_REQUEST : "INVALID_REQUEST",
    IN_PROGRESS : "IN_PROGRESS",
    REFUNDED : "REFUNDED"
}
export const STORE_DESCRIPTION_TOOLTIP = "Click on this button to purchase your desired packages"
export const QWIKCILVER_REDEEM_INSTRUCTIONS = "Enter Gift Card Id and Gift Card Pin to redeem your Voucher."
export const MINTROUTE_REDEEM_INSTRUCTIONS = "Enter Player ID and Voucher Code to redeem your Voucher."
export const ZAIN_DCB = "Zain DCB"
export const RAZORPAY = "Razorpay"

export const PAYMENT_OPTIONS = {
    "zain" : "zain",
    "razorpay" : "razorpay",
}
