
<template>
    <div>
        <b-navbar toggleable="xl" type="dark" variant="dark">
            <b-navbar-brand>
                <router-link :to="{ name: 'StorePage' }"><img src="../assets/moonfrog-logo.png" alt="logo" width="100" /></router-link>
    
            </b-navbar-brand>
            <b-navbar-brand v-if="walletCredits" style="pointer-events: none">
                <p id="mfcHeader" class="p-class btn">MF Credits: {{ credits }} <b>{{ walletCredits.currency }}</b></p>
                <p id="giftCardId" class="p-class btn" v-if="giftCard">Gift Card: {{ giftCard }}</p>
            </b-navbar-brand>
            <b-navbar-toggle style="padding: 0" target="nav-collapse"></b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="ml-auto">
                    <b-nav-item>
    
                        <router-link :active-class="this.$router.currentRoute.path==='/' ? 'activePage':''" class="p-class btn" to="/">Reseller</router-link>
                    </b-nav-item>
                    <b-nav-item v-if="showStore()">
                        <router-link :active-class="this.$router.currentRoute.path.startsWith('/store') ? 'activePage':''" class="p-class btn" to="/store">Store</router-link>
                    </b-nav-item>
                    <b-nav-item v-if="showRedeem()">
                        <router-link :active-class="this.$router.currentRoute.path.startsWith('/redeem') ? 'activePage':''" class="p-class btn" to="/redeem">Redeem</router-link>
                    </b-nav-item>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>

<script>
import apiConfig from "../config/api.config";
import * as SageConstants from "../config/SageConstants";
import * as StoreWebClient from "../client/StoreWebClient";

export default {
    name: "StorePageHeader",
    props: {
        walletCredits: {
            type: Object,
            default: function(props){
                return null
            },
        },
        giftCard: {
            type: String,
            default: ""
        }
       
    },
    data() {
        return {
            isRetailer: Boolean,
            section : []
        };
    },
    async created() {
        if (this.$store.state.account.userType ===
            SageConstants.userTypeConstants.RETAILER) {
            this.isRetailer = true;
        } else this.isRetailer = false;
        const res = await StoreWebClient.getStoreConfig()
        if(res ){
            if(res.config.store && res.config.store.display){
            this.section.push("Store")
            }
            if (res.config.vouchers){
                let count = 0
                SageConstants.GiftVoucherPartners.forEach((vp)=>{
                    if(res.config.vouchers[vp.id].display){
                        ++count
                    }
                })
                count ? this.section.push("Redeem") : ""
            }
      }
    },
    methods: {
        showRedeem(){
            return this.section.includes("Redeem")
        },
        showStore(){
            return this.section.includes("Store")
        },
        signOut() {
            this.$stats.StatsController.getInstance(
                this.$store.state.account.email,
                apiConfig.gameId,
                apiConfig.installOS,
                process.env.VUE_APP_STATS_URL
            ).count("mfsage", 1, "logout");
            localStorage.removeItem("access_token");
            this.$store.commit("resetAccount");
            this.$router.push("/");
        },
    },
    computed: {
        credits: function() {
            if (!this.walletCredits) {
                return 0;
            }
            return this.walletCredits.amount.toFixed(2);
        },
    },
};
</script>

<style scoped>
.p-class {
    font-size: 1rem;
    font-weight: 1000;
    line-height: 1.7;
    color: white;
    text-transform: uppercase;
    margin-bottom: 0%;
}

.activePage {
    background-color: #007bff;
}

.p-class:hover {
    text-decoration: underline;
}

@media (max-width: 331px) {
    #mfcHeader,
    #giftCardId {
        font-size: 0.435rem;
    }
}

@media (min-width: 332px) and (max-width: 349px) {
    #mfcHeader,
    #giftCardId {
        font-size: 0.5rem;
    }
}

@media (min-width: 350px) and (max-width: 365px) {
    #giftCardId,
    #mfcHeader {
        font-size: 0.6rem;
    }
}

@media (min-width: 366px) and (max-width: 383px) {
    #mfcHeader,
    #giftCardId {
        font-size: 0.7rem;
    }
}

@media (min-width: 384px) and (max-width: 401px) {
    #mfcHeader,
    #giftCardId {
        font-size: 0.8rem;
    }
}

@media (min-width: 402px) and (max-width: 417px) {
    #mfcHeader,
    #giftCardId {
        font-size: 0.9rem;
    }
}
</style>