<template>
  <div class="bg">
    <!-- Navbar-->
    <StorePageHeader/>
    <header class="header">
      <nav class="navbar navbar-expand-lg navbar-light py-3">
        <div class="container">
          <!-- Navbar Brand -->
         
        </div>
      </nav>
    </header>

    <div class="container">
      <div class="align-items-center row py-5 mt-4">
        <!-- For Left Half -->
        <div class="col-md-5 pr-lg-5 mb-5 mb-md-0">
          <PageHeading
            id="pg_heading"
            :imgsrc="getImgURL()"
            :heading="title"
            :description="desc"
          />
          <!-- <v-divider class="col-lg-12 mt-9 mb-9"></v-divider> -->
          <!-- Store Page -->
          <!-- <ActionButton id="submit-btn" text="Open Qwikcilver Page" @onclick="openQwikcilverPage"/> -->
          <!-- <PageHeading
              id="pg_heading"
              :description="storeDesc"
          /> -->
        </div>

        <!-- Registration Form -->
        <div class="col-md-7 col-lg-6 ml-auto">
          <form id="signup-form" action="#">
            <div class="row">
              <!-- Name -->
              <!-- <div class="input-group col-lg-12 mb-4">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-user text-muted"></i>
                  </span>
                </div>
                <input
                  v-model="resellerDetails.name"
                  id="name"
                  type="text"
                  name="name"
                  placeholder="Name"
                  class="form-control bg-white border-left-0 border-md"
                />
              </div> -->
              <!-- Email ID -->
              <!-- <div class="input-group col-lg-12 mb-4">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-envelope text-muted"></i>
                  </span>
                </div>
                <input
                  v-model="resellerDetails.email"
                  id="email"
                  type="text"
                  name="email"
                  placeholder="Gmail ID"
                  class="form-control bg-white border-left-0 border-md"
                />
              </div> -->
              <!-- Phone Number -->
              <!-- <div class="input-group col-lg-12 mb-4" id="phone-number">
                <div class="input-group-prepend">
                  <span
                    class="
                      input-group-text
                      bg-white
                      px-4
                      border-md border-right-0
                    "
                  >
                    <i class="fa fa-phone-square text-muted"></i>
                  </span>
                </div>
                <select
                  v-model="countryCode"
                  id="countryCode"
                  name="countryCode"
                  style="max-width: 80px"
                  class="
                    custom-select
                    form-control
                    bg-white
                    border-left-0 border-md
                    h-100
                    font-weight-bold
                    text-muted
                  "
                >
                  <option value="+91">+91</option>
                  <option value="+880">+880</option>
                </select>
                <input
                  v-model="resellerDetails.mobileNumber"
                  id="phoneNumber"
                  type="tel"
                  name="phone"
                  placeholder="Phone Number"
                  class="form-control bg-white border-md border-left-0 pl-3"
                  @keyup="checkMobileNumber('keyup')"
                />
              </div> -->
              <!-- Attach KYC -->
              <!--<div class="input-group col-lg-12 mb-4" id="attach-kyc">
                <AttachFile
                  text="Upload KYC Proof Document"
                  @onAttach="kycAttached"
                  :warning="kycNotSubmitted"
                  style="width: auto"
                />
              </div>
              <p
                style="
                  margin-bottom: 1rem;
                  font-size: 14px;
                  margin-left: 2rem;
                  margin-right: 2rem;
                  font-weight: 300;
                "
              >
                By clicking Submit, you agree to the Moonfrog Labs
                <a
                  href="https://moonfroglabs.com/terms-of-service/"
                  target="_blank"
                  >Terms of Service</a
                >,
                <a
                  href="https://moonfroglabs.com/privacy-policy-2/"
                  target="_blank"
                  >Privacy Policy</a
                >
                and
                <router-link :to="{ name: 'distributorTnC' }" target="_blank">
                  Terms &amp; Conditions</router-link
                >, that you may be bound by creating this account with Moonfrog
                Labs.
              </p>-->
              <!-- Submit Button -->
              <!-- <ActionButton id="submit-btn" text="Submit" @onclick="submit" /> -->
              <!-- Login  -->
              <!-- <v-divider class="col-lg-12 mt-9 mb-9"></v-divider> -->
              <div class="col-lg-12" id="signin">
                <p>Already Registered? Sign in with Google.</p>
                <GSignIn
                  @onSignIn="onSignIn"
                  style="text-align: -webkit-center"
                />
              </div>
            </div>
          </form>

          <div id="frogie" style="display: none">
            <img
              src="../assets/Frogie.jpg"
              alt
              class="img-fluid mb-3 d-none d-md-block"
              style="width: 50%; margin: auto"
            />
            <h1>Thank you for providing your details!</h1>
            <p>
              We have sent you an email with the link for your KYC journey. Use
              the link provided in the email to login to your account and follow
              the steps.
            </p>
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="isLoading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </div>
</template>

<script>
import PageHeading from "@/components/PageHeading";
import ActionButton from "@/components/ActionButton";
import GSignIn from "@/components/GSignIn";
// import AttachFile from "@/components/AttachFile";
import * as client from "@/client/webclient";
import * as SageConstants from "../config/SageConstants";
import apiConfig from "../config/api.config";
import * as StoreWebClient from "../client/StoreWebClient";
import StorePageHeader from "../components/StorePageHeader.vue";

export default {
  name: "SignUp",
  components: {
    PageHeading,
  // ActionButton,
    GSignIn,
    StorePageHeader
},
  props: {},
  async created() {
    await this.init()
  },
  data() {
    return {
      title: "Register as Game Reseller",
      desc: "Submit your details and we will contact you for confirmation.",
      disabled: true,
      countryCode: "+91",
      isLoading: false,
      storeTitle: "Store Page",
      
      // kycNotSubmitted: false,

      resellerDetails: {
        name: "",
        email: "",
        mobileNumber: "",
        // kycObj: null,
      },
      section : []
    };
  },
  methods: {
    async init() {
      // this.isLoading = true
      // const res = await StoreWebClient.getStoreConfig()
      // console.log("Res :: " , res)
      // if(res ){
      //   if(res.store && res.store.display){
      //     this.section.push("Store")
      //   }
      //   if (res.vouchers){
      //     this.section.push("Redeem")
      //   }
      // }
      // console.log("Section :: " , this.section)
      // this.isLoading = false

    },
    async submit() {
      this.resellerDetails.name = this.resellerDetails.name.trim();
      this.resellerDetails.email = this.resellerDetails.email
        .toLowerCase()
        .trim();
      /* eslint-disable no-control-regex */
      const re =
        /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
      /* eslint-enable no-control-regex */
      if (
        !re.test(this.resellerDetails.email) ||
        this.resellerDetails.email.indexOf(" ") > -1
      ) {
        this.$swal
          .fire({
            icon: "error",
            text: "Email ID syntax not valid.",
          })
          .then(() => {
            this.resellerDetails.email = "";
            return;
          });
        return;
      }

      let err = false;
      this.resellerDetails.name.length === 0
        ? (this.showWarningBorder("name"), (err = true))
        : this.showNormalBorder("name");
      this.resellerDetails.email.length === 0
        ? (this.showWarningBorder("email"), (err = true))
        : this.showNormalBorder("email");
      this.countryCode.length === 0
        ? (this.showWarningBorder("countryCode"), (err = true))
        : this.showNormalBorder("countryCode");
      // this.resellerDetails.kycObj === null
      //   ? ((this.kycNotSubmitted = true), (err = true))
      //   : (this.kycNotSubmitted = false);
      this.checkMobileNumber("submit")
        ? this.showNormalBorder("phoneNumber")
        : (this.showWarningBorder("phoneNumber"), (err = true));

      if (err) return;

      try {
        this.isLoading = true;
        const mfUser = await client.getUser(this.resellerDetails.email);
        let resellerDetailsObj = await this.convertToFormData();
        if (!mfUser) {
          await client.createReseller(resellerDetailsObj);
          this.title = "Registration Successful";
          this.desc = "";
          document.getElementById("signup-form").style.display = "none";
          document.getElementById("frogie").style.display = "block";
          // } else if (
          //   (mfUser.status === SageConstants.resellerConstants.PENDING ||
          //     mfUser.status === SageConstants.resellerConstants.REJECTED) &&
          //   mfUser.userType === SageConstants.userTypeConstants.RESELLER
          // ) {
          //   this.$swal
          //     .fire({
          //       icon: "warning",
          //       text: "Email ID already requested for Onboarding. If you proceed, previous request will be overwritten.",
          //       showDenyButton: true,
          //       confirmButtonText: `Proceed`,
          //       denyButtonText: `Change Email ID`,
          //     })
          //     .then(async (result) => {
          //       if (result.isConfirmed) {
          //         await client.createReseller(resellerDetailsObj);
          //         this.title = "Registration Successful";
          //         this.desc = "";
          //         document.getElementById("signup-form").style.display = "none";
          //         document.getElementById("frogie").style.display = "block";
          //       } else if (result.isDenied) {
          //         this.resellerDetails.email = "";
          //         return;
          //       }
          //     })
        } else {
          this.$swal
            .fire({
              icon: "error",
              text: "Email ID already Registered.",
            })
            .then(() => {
              this.resellerDetails.email = "";
              return;
            });
        }
      } catch (er) {
        this.showConnectionErrorDialog(er);
      } finally {
        this.isLoading = false;
      }
    },
    checkMobileNumber(event) {
      let requiredLength;
      switch (this.countryCode) {
        case "+91":
          requiredLength = 10;
          break;
        case "+880":
          requiredLength = 10;
          break;
      }
      let mob = this.resellerDetails.mobileNumber;
      if (event === "keyup") {
        const num = parseInt(mob);
        if (
          isNaN(num) ||
          num.toString().length < mob.length ||
          num.toString().length > requiredLength
        ) {
          mob = mob.slice(0, -1);
          document.getElementById("phoneNumber").value = mob;
          this.resellerDetails.mobileNumber = mob;
        }
      } else if (event === "submit") {
        return mob.length === requiredLength;
      }
    },
    // kycAttached(fileObj) {
    //   this.resellerDetails.kycObj = fileObj;
    // },
    convertToFormData() {
      const phoneNumber = this.countryCode + this.resellerDetails.mobileNumber;
      // let formData = new FormData();
      // formData.append("emailId", this.resellerDetails.email);
      // formData.append("userType", SageConstants.userTypeConstants.RESELLER);
      // formData.append("name", this.resellerDetails.name);
      // formData.append("status", SageConstants.resellerConstants.PENDING);
      // formData.append("phoneNumber", phoneNumber);
      // formData.append("kycObj", this.resellerDetails.kycObj);
      // return formData;

      let returnObj = {
        emailId: this.resellerDetails.email,
        userType: SageConstants.userTypeConstants.RESELLER,
        name: this.resellerDetails.name,
        status: SageConstants.resellerConstants.PENDING,
        phoneNumber: phoneNumber,
        requestorEmail: this.resellerDetails.email,
      };
      return returnObj;
    },
    onSignIn(queryData) {
      const user = queryData.user;
      client
        .getUser(user.email.toLowerCase())
        .then((mfUser) => {
          if (!mfUser) {
            this.showConnectionErrorDialog("Email is not registered");
            return;
          } else if (mfUser.status !== SageConstants.resellerConstants.ACTIVE) {
            this.showConnectionErrorDialog("Email ID is not activated.");
            return;
          } else if (
            mfUser.userType === SageConstants.userTypeConstants.RESELLER ||
            mfUser.userType === SageConstants.userTypeConstants.SUBRESELLER ||
            mfUser.userType === SageConstants.userTypeConstants.RETAILER
          ) {
            this.$store.commit("setAccount", mfUser.id);
            this.$store.commit("setProfilePic", user.picture);
            this.$router
              .push({ name: "home", params: { fromSignUp: true } })
              .catch((err) => {
                this.$router.push({
                  name: "home",
                  params: { fromSignUp: true },
                });
              });
            this.$stats.StatsController.getInstance(
              user.email,
              apiConfig.gameId,
              apiConfig.installOS,
              process.env.VUE_APP_STATS_URL
            ).count("mfsage", 1, "login");
          } else if (mfUser.userType === "MFOPS") {
            this.$store.commit("setAccount", mfUser.id);
            this.$router.push("/mfops").catch((err) => {
              this.$router.push("/mfops");
            });
          }
          this.$store.commit("setUserType", mfUser.userType);
          localStorage.setItem("access_token", queryData.jwt);
        })
        .catch((err) => {
          this.showConnectionErrorDialog(err);
        });
    },
    openQwikcilverPage(){
      this.$router.push('/store');
    },
    getImgURL() {
      return require("../assets/Left_Panel_Image.svg");
    },
    showWarningBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-md");
        document.getElementById(id).classList.add("border-danger");
      }
    },
    showNormalBorder(id) {
      if (document.getElementById(id) !== null) {
        document.getElementById(id).classList.remove("border-danger");
        document.getElementById(id).classList.add("border-md");
      }
    },
    showConnectionErrorDialog(error) {
      this.$swal.fire({
        icon: "error",
        text: error,
      });
    },
  },
};
</script>

<style scoped>
#submit-btn {
  color: #fff;
}

.bg {
  background: rgba(141, 203, 16, 0.748)
;
  min-height: 100vh;
  max-width: none;
}
</style>